import React, { useContext } from 'react';
import { Link, matchPath, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import '../css//Menu.css';
import { requireAuthenticationContext } from '../App';
import { useAuth0 } from '@auth0/auth0-react';
import { isOpenMenuContext } from '../App';

type CustomLinkProps = {
    children: React.ReactNode,
    to: string
}

function CustomLink({ children, to }: CustomLinkProps) {
    const { pathname } = useLocation();
    let isMatch;
    if (to === '/') {
        isMatch = ["/"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/account') {
        isMatch = ["/account"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/change_email') {
        isMatch = ["/change_email"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/reset_mfa') {
        isMatch = ["/reset_mfa", "mfa_token", "/delete_mfa", "/select_mfa", "maff_app_install", "maff_app_register", "success_register_mfa", "sms_entry", "sms_code_entry", "mail_entry"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/service') {
        isMatch = ["/service"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/delete') {
        isMatch = ["/delete"].find((path) => !!matchPath(path, pathname));
    } else if (to === '/activity') {
        isMatch = ["/activity"].find((path) => !!matchPath(path, pathname));
    }
    return (
        <Link className={isMatch !== undefined ? 'link--inverse-menu is-active' : 'link--inverse-menu'} to={to}>
            {children}
        </Link>
    );
}

const Menu: React.FC = () => {
    const { user, isAuthenticated } = useAuth0();
    const requireAuthContext = useContext(requireAuthenticationContext);
    const { isOpenMenu, setIsOpenMenu } = React.useContext(isOpenMenuContext);

    // メニュー表示対象の画面かどうかを判定
    const { pathname } = useLocation();
    const showMenu = !["/", "/accept_change_email", "/verify_change_email", "/success_change_email", "/logout", "/error"].find((path) => !!matchPath(path, pathname));

    // ユーザーのログイン方法（gBizID/eMAFF ID）を取得
    const isgBizID = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/isgBizIDLogin`] : false;

    // ユーザーの共通申請アカウントの権限を取得
    const iseMAFFAdmnin = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/iseMAFFAdmin`] : false;

    // 共通申請アカウントの有無を取得
    const emaffInfo = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/emaffInfo`] : {};
    const haseMAFFId = emaffInfo ? emaffInfo["eMAFFID"] ? true : false : false;

    if (requireAuthContext && !isAuthenticated) {
        return (
            <div id="sub" className='no-submenu' ></div>
        );
    } else {
        return (
            <div id="sub" className={`${showMenu ? '' : 'no-submenu'} ${isOpenMenu ? 'active' : ''}`} >
                <ul className="vertical-menu ul--reset" onClick={() => setIsOpenMenu(false)}>
                    <li className="vertical-menu-item">
                        <CustomLink data-vertical="0" to="/">ホーム</CustomLink>
                    </li>
                    <li className="vertical-menu-item">
                        <CustomLink data-vertical="1" to="/account">アカウント情報</CustomLink>
                    </li>
                    <li className="vertical-menu-item">
                        <CustomLink data-vertical="3" to="/reset_mfa">二要素認証の設定</CustomLink>
                    </li>
                    <li className="vertical-menu-item">
                        <CustomLink data-vertical="4" to="/service">連携サービス一覧</CustomLink>
                    </li>
                    <li className="vertical-menu-item">
                        <CustomLink data-vertical="5" to="/activity">最近のアクティビティ</CustomLink>
                    </li>
                    {/* {isgBizID ? null :
                        <li className="vertical-menu-item">
                            <CustomLink data-vertical="6" to="/delete">eMAFF ID削除</CustomLink>
                        </li>
                    } */}
                </ul>
                <i className='active-marker'></i>
            </div>
        );
    }
}

export default Menu;