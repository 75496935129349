import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { isMfaLoginContext } from '../App';
import { refreshTokenContext } from '../App';
import { GetMfaToken } from '../util/GetMfaToken';

const EnrollDummySms: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { isMfaLogin, setIsMfaLogin } = React.useContext(isMfaLoginContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { refreshToken, setRefreshToken } = React.useContext(refreshTokenContext);

    useEffect(() => {
        const enrollDummySmsAuthenticator = async () => {
            try {
                console.log('メール認証登録開始');
                /*
                // const mfaToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access enroll" });
                const token = await GetMfaToken(refreshToken);
                const mfaToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);

                if (!mfaToken || mfaToken === '') {
                    // アクセストークン取得に失敗した場合はエラー
                    setIsMfaLogin(false);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // ダミーSMS認証の登録開始
                // Add Authenticator
                const addAuthUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/associate/`;

                // body
                const params = {
                    "client_id": `${process.env.REACT_APP_AUTH0_CLIENT_ID!}`,
                    "authenticator_types": ["oob"],
                    "oob_channels": ["sms"],
                    "phone_number": `+99999999999`
                }

                // API呼び出し
                const addResponse = await fetch(addAuthUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${mfaToken}`,
                    },
                    body: JSON.stringify(params)
                });

                if (addResponse.status !== 200) {
                    // エラー
                    const response = await addResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // SMS送信成功
                const response = await addResponse.json();
                console.log(response);
                const oobCode = response["oob_code"];

                // user_metadata から bindingcode を取得
                let accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`, scope: "read:current_user update:current_user_metadata" });

                const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`;

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });


                if (metadataResponse.status !== 200) {
                    // エラー
                    const response = await metadataResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // user_metadata取得成功
                const { user_metadata } = await metadataResponse.json();
                const bindingCode = user_metadata["bindingCode"];

                // 確認コード送信
                // Verify OOB
                const verifyCodeUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;

                // body
                const verifyParams = {
                    "grant_type": "http://auth0.com/oauth/grant-type/mfa-oob",
                    "client_id": `${process.env.REACT_APP_AUTH0_CLIENT_ID!}`,
                    "mfa_token": mfaToken,
                    "oob_code": oobCode,
                    "binding_code": bindingCode
                }

                // API呼び出し
                const now = Date.now;
                const verifyResponse = await fetch(verifyCodeUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(verifyParams)
                });
                // 結果取得
                if (verifyResponse.status !== 200) {
                    // エラー
                    const response = await verifyResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // email_mfaフラグ更新
                // accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/` });

                const updateUserUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`;

                user_metadata["email_mfa"] = true;

                const updateParams = {
                    "user_metadata": user_metadata
                };
                const updateResponse = await fetch(updateUserUrl, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updateParams)
                });

                // 結果取得
                if (updateResponse.status === 200) {
                    // 登録成功
                    navigate('/success_register_mfa', { state: { type: 'mail' } });
                } else {
                    // その他のエラー
                    const response = await updateResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                }
                */

                // アクセストークンを取得
                const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/` });

                // メール認証設定API
                const enrollDummySmsApiUrl = `${process.env.REACT_APP_AUTH0_REST_API_INTERNET_URL}enrollDummySms`;

                // body
                const params = {
                    "userid": user?.sub
                }

                // API呼び出し
                const enrollDummySmsResponse = await fetch(enrollDummySmsApiUrl, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(params)
                });

                // 結果取得
                if (enrollDummySmsResponse.status === 200) {
                    // メール認証設定成功
                    navigate('/success_register_mfa', { state: { type: 'mail' } });
                } else {
                    console.log(enrollDummySmsResponse);
                    // メール認証設定失敗
                    const response = await enrollDummySmsResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                }


            } catch (e) {
                console.log(e);
            }
        };
        enrollDummySmsAuthenticator();
    }, []);

    return null;
}

export default EnrollDummySms;