import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation } from "react-router-dom";
import { isModifyMFAContext } from '../App';

const CompleteMfa: React.FC = () => {
    interface State {
        type: string;
    }
    const location = useLocation();
    const { type } = location.state as State;
    const { user } = useAuth0();
    const { isModifyMFA, setIsModifyMFA } = React.useContext(isModifyMFAContext);

    return (
        <div className='content-wrapper'>
            <div className="screenTitle">
                <h1>二要素認証の設定完了</h1>
            </div>
            <div>
                二要素認証方法を{type === 'push' ? 'MAFFアプリ（農林水産省）' : type === 'sms' ? 'SMS' : 'メール'}{isModifyMFA ? 'に変更' : 'に設定'}しました。<br />
            </div>
            <div style={{ marginTop: "30px" }}>
                <Link to="/" className="button02 w-100">ホームへ戻る</Link>
            </div>

        </div>
    )
}

export default CompleteMfa;