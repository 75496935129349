import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../css/InstallMaffApp.css';

const InstallMaffApp: React.FC = () => {
    const { user } = useAuth0();

    return (
        <div>
            <div className="screenTitle">
                <h1>MAFFアプリ（農林水産省）のインストール</h1>
            </div>
            <div>
                MAFFアプリ（農林水産省）のプッシュ通知による二要素認証の初期設定を行います。<br />
                お使いのスマートフォンから以下のQRコードをスキャンし、App StoreまたはGoogle PlayでMAFFアプリ（農林水産省）を入手してください。<br />
                MAFFアプリ（農林水産省）のインストールが完了しましたら、「続ける」をクリックしてください。<br />
                または、「別の方法で設定する」をクリックしてください。
            </div>
            <div className="maffapp-code-wrapper">
                <div className="maffapp-code">
                    App Store
                    <img src="../image/qrcode_apps.apple.com.png" style={{ width: "250px" }} />
                    <a href="https://apps.apple.com/jp/app/maff%E3%82%A2%E3%83%97%E3%83%AA/id1503326833" target='_blank'><img src="../image/appStoreBadge.svg" style={{ height: "60px", padding: "14.1px" }} /></a>
                </div>
                <div className="maffapp-code">
                    Google Play
                    <img src="../image/qrcode_play.google.com.png" style={{ width: "250px" }} />
                    <a href="https://play.google.com/store/apps/details?id=jp.co.maff.maffapp&hl=ja&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target='_blank'><img alt='Google Play で手に入れよう' src='../image/google-play-badge.png' style={{ height: "88.2px" }} /></a>
                </div>
            </div>
            <div style={{ marginTop: "30px", display: "flex", flexDirection: "row" }}>
                <Link to="/maff_app_register" className="button02" style={{ marginRight: "10px" }}>続ける</Link>
                <Link to="/select_mfa" className="button03" id="mfaCancel">別の方法で設定する</Link>
            </div>
        </div >
    );
}

export default InstallMaffApp;