import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive';
import '../css/Activity.css';
import Loader from "./Loader";

const Activity: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const location = useLocation();
    const [usersLog, setUsersLog] = useState(Array<logType>);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [head, setHead] = useState(0);
    const [tail, setTail] = useState(10);
    const [dpp, setDpp] = useState(10);
    const isDesktop = useMediaQuery({ minWidth: 960 })
    const [checkedState, setCheckedState] = useState(Array<boolean>);

    type logType = {
        date: string
        event: string
        ip: string
        client: string
        location: string
        os: string
        browser: string
    }

    useEffect(() => {
        const getUsersLog = async () => {
            console.log('レコード取得開始');
            try {
                // アクセストークン取得
                let accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`, scope: "read:current_user update:current_user_metadata" });

                // Management APIでログ取得
                const getUsersLogApiUrl = `${process.env.REACT_APP_AUTH0_REST_API_INTERNET_URL}getUsersLog?`;

                // body
                let getUsersLogParams = new URLSearchParams();
                getUsersLogParams.set("userid", `${user?.sub}`);
                getUsersLogParams.set("username", `${user?.name}`);

                // API呼び出し
                const getUsersLogResponse = await fetch(getUsersLogApiUrl + getUsersLogParams.toString(), {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                });

                // 結果取得
                if (getUsersLogResponse.status === 200) {
                    // ログ取得成功
                    const usersLog = await getUsersLogResponse.json();
                    setUsersLog(usersLog)
                    setLoading(false);
                    console.log(usersLog);
                    setCheckedState(new Array(usersLog.length).fill(false));
                } else {
                    console.log(getUsersLogResponse);
                    // ログ取得失敗
                    const response = await getUsersLogResponse.json();
                    navigate('/error', { state: { name: '最近のアクティビティ' }, replace: true });
                }

            } catch (e) {
                navigate('/error', { state: { name: '最近のアクティビティ' }, replace: true });
            }
        };
        getUsersLog();
    }, []);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    }

    interface RecordProps {
        data: Array<logType>;
    }

    const RenderLogRecord = (props: RecordProps) => {
        return (
            <table className='activity'>
                <thead>
                    <tr>
                        <th style={{ "width": "15em" }}>日時</th>
                        <th style={{ "width": "25em" }}>イベント</th>
                        <th style={{ "width": "20em" }}>サービス</th>
                        <th style={{ "width": "20em" }}>IPアドレス</th>
                        <th style={{ "width": "10em" }}>場所</th>
                        <th style={{ "width": "15em" }}>利用環境</th>
                    </tr>
                </thead>
                <tbody>
                    {props.data.map(item => (
                        <tr>
                            <td className='activity'>{item.date}</td>
                            <td className='activity'>{item.event}</td>
                            <td className='activity'>{item.client}</td>
                            <td className='activity'>{item.ip}</td>
                            <td className='activity'>{item.location}</td>
                            <td>{item.os}<br/>{item.browser}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )
    };

    const RenderLogRecordMobile = (props: RecordProps) => {
        return (
            <>
                {
                    props.data.map((item, index) => (
                        <div className='activityContainer' key={index}>
                            <input title='hiddenCheckbox' type='checkbox' className='hiddenCheckbox-activity' checked={checkedState[index]} onChange={() => handleOnChange(index)} />
                            <label className='activity' onClick={() => handleOnChange(index)}>
                                <div style={{ width: '265px', marginTop: '10px', fontSize: '0.9rem' }}>{item.date}<br />{item.event}</div>
                            </label>
                            <div className='content'>
                                <div className='activity_content_item head'>サービス</div>
                                <div className='activity_content_item'>{item.client}</div>
                                <div className='activity_content_item head'>IPアドレス</div>
                                <div className='activity_content_item'>{item.ip}</div>
                                <div className='activity_content_item  head'>場所</div>
                                <div className='activity_content_item'>{item.location}</div>
                                <div className='activity_content_item head'>利用環境</div>
                                <div className='activity_content_item'>{item.os}</div>
                                <div className='activity_content_item'>{item.browser}</div>
                            </div>
                        </div>
                    ))
                }
            </>
        )
    };

    function pageChange(data: any) {
        let pageNumber = data['selected']; //選択されたページ番号
        setCurrentPage(pageNumber);
        setHead(pageNumber * dpp);
        setTail(pageNumber * dpp + dpp);
        setCheckedState(new Array(usersLog.length).fill(false));
    }
    // 総ページ数を算出
    const totalPage = Math.ceil(usersLog.length / dpp);

    if (loading) {
        return (
            <Loader />
        );
    } else {
        return (
            <div>
                <div className="screenTitle">
                    <h1>最近のアクティビティ</h1>
                </div>
                <div className="infoText">
                    ※過去30日間のアクティビティを表示しています。<br />
                    ※「場所」はアクセス元IPアドレスから推測した情報です。<br />
                </div>
                {loading ?
                    <div className="loader">Loading...</div>
                    :
                    <div style={{ marginTop: "1rem" }}>
                        {isDesktop ?
                            <RenderLogRecord data={usersLog.slice(head, tail)} />
                            :
                            <RenderLogRecordMobile data={usersLog.slice(head, tail)} />}
                        <ReactPaginate
                            pageCount={Math.ceil(usersLog.length / dpp)} //総ページ数。今回は一覧表示したいデータ数 / 1ページあたりの表示数としてます。
                            marginPagesDisplayed={isDesktop? 2 : 1} //先頭と末尾に表示するページの数。今回は2としたので1,2…今いるページの前後…後ろから2番目, 1番目 のように表示されます。
                            pageRangeDisplayed={isDesktop? 5 : 1} //上記の「今いるページの前後」の番号をいくつ表示させるかを決めます。
                            onPageChange={pageChange} //ページネーションのリンクをクリックしたときのイベント(詳しくは下で解説します)
                            containerClassName={isDesktop ? 'paginationPC' : 'paginationMobile'} //ページネーションリンクの親要素のクラス名
                            pageClassName='page-item' //各子要素(li要素)のクラス名
                            pageLinkClassName='page-link' //ページネーションのリンクのクラス名
                            activeClassName='active' //今いるページ番号のクラス名。今いるページの番号だけ太字にしたりできます 
                            previousLabel='<' //前のページ番号に戻すリンクのテキスト
                            nextLabel='>' //次のページに進むボタンのテキスト
                            previousClassName='page-item' // '<'の親要素(li)のクラス名
                            nextClassName='page-item' //'>'の親要素(li)のクラス名
                            previousLinkClassName='page-link'  //'<'のリンクのクラス名
                            nextLinkClassName='page-link'　//'>'のリンクのクラス名
                            disabledClassName='disabled' //先頭 or 末尾に行ったときにそれ以上戻れ(進め)なくするためのクラス
                            breakLabel='...' // ページがたくさんあるときに表示しない番号に当たる部分をどう表示するか
                            breakClassName='page-item' // 上記の「…」のクラス名
                            breakLinkClassName='page-link' // 「…」の中のリンクにつけるクラス
                        />
                    </div>
                }
            </div>
        );
    }
}

export default Activity;