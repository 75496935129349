import React from 'react';
import Modal from "react-modal";
import { isOpenResetMfaModalContext } from './ResetMfa';
import '../css/ModalDialog.css';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const ResetMfaModalDialog: React.FC = () => {
    const { isOpenResetMfaModal, setIsOpenResetMfaModal } = React.useContext(isOpenResetMfaModalContext);
    const navigate = useNavigate();
    const isDesktop = useMediaQuery({ minWidth: 960 })

    // モーダルダイアログのスタイル
    let modalStyle: { [key: string]: React.CSSProperties };

    if (isDesktop) {
        modalStyle = {
            overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: "#d9d9d9bf",
                zIndex: 90
            },
            content: {
                position: "absolute",
                // width: "70%",
                maxWidth: "50rem",
                height: "6rem",
                margin: "auto",
                zIndex: 99,
                backgroundColor: "#fff",
                borderRadius: "1rem",
                padding: "1.5rem"
            }
        };
    } else {
        modalStyle = {
            overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                backgroundColor: "#d9d9d9bf",
                zIndex: 90
            },
            content: {
                position: "absolute",
                // width: "70%",
                maxWidth: "50rem",
                height: "8rem",
                margin: "auto",
                zIndex: 99,
                backgroundColor: "#fff",
                borderRadius: "1rem",
                padding: "1.5rem"
            }
        };
    }

    const noneModalStyle: { [key: string]: React.CSSProperties } = {
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "#d9d9d9bf",
            zIndex: 90
        },
        content: {
            position: "absolute",
            width: 0,
            height: 0,
            zIndex: 99
        }
    };

    return (
        // 二要素認証登録解除の確認ダイアログ
        <div>
            <Modal isOpen={isOpenResetMfaModal} onRequestClose={() => setIsOpenResetMfaModal(false)} style={modalStyle}>
                <div className="modalContents">
                    現在の二要素認証方法の設定は解除されますが、よろしいでしょうか。<br />
                    <div className="modal-button-wrapper">
                        <Link to="/mfa_token" state={{ from: "ResetMfa" }} className="modal-normal" id="mfaOK" style={{ marginRight: "10px" }} onClick={() => setIsOpenResetMfaModal(false)}>はい</Link>
                        <a href="#" className="modal-normal" id="mfaCancel" onClick={() => setIsOpenResetMfaModal(false)}>いいえ</a>
                    </div>
                </div>
                <div id="closeConfirmModal" className="closeModal" onClick={() => setIsOpenResetMfaModal(false)}>
                    X
                </div>

            </Modal>
        </div>
    );
};

export default ResetMfaModalDialog;