import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate } from 'react-router-dom';
import ResetMfaModalDialog from './ResetMfaModal';
import { isModifyMFAContext, dataValues } from '../App';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import ClientList from './MfaClientList-web-internet.json';
import '../css/ResetMfa.css';
import { FaLess } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';

export const isOpenResetMfaModalContext = React.createContext({} as {
  isOpenResetMfaModal: boolean
  setIsOpenResetMfaModal: React.Dispatch<React.SetStateAction<boolean>>
});

const ResetMfa: React.FC = () => {
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [authMethod, setAuthMethod] = useState('');
  const [emailMfaFlg, setEmailMfaFlg] = useState(false);
  const [isOpenResetMfaModal, setIsOpenResetMfaModal] = React.useState(false);
  const navigate = useNavigate();
  const { isModifyMFA, setIsModifyMFA } = React.useContext(isModifyMFAContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [enableMfaFlgList, setEnableMfaFlgList] = useState<flgValues>();
  const [isLoading, setIsLoading] = useState(true);
  const isDesktop = useMediaQuery({ minWidth: 960 })

  type flgValues = {
    [key: string]: boolean;
  }

  useEffect(() => {
    const getAuthenticator = async () => {
      try {
        const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`, scope: "read:current_user" });
        const getEnrollmentUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}/enrollments`;
        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`;

        // 現在登録されている二要素認証方法を取得
        const enrollmentResponse = await fetch(getEnrollmentUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const enrollment = await enrollmentResponse.json();
        setIsModifyMFA(true);
        if (!enrollment[0] || enrollment[0]["status"] === 'pending') {
          setAuthMethod("未登録");
          setIsModifyMFA(false);
        } else if (enrollment[0]["auth_method"] === "sms") {
          setAuthMethod("SMS");
        } else {
          setAuthMethod("MAFFアプリ（農林水産省）");
        }
      } catch (e) {
        console.log(e);
        navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
      }
    };

    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`, scope: "read:current_user" });
        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`;

        // 最新の user_metadata を取得
        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { user_metadata } = await metadataResponse.json();
        setEmailMfaFlg(user_metadata["email_mfa"] || false);
        setEnableMfaFlgList(user_metadata["enableMfa"] || {});
        setIsLoading(false);

      } catch (e) {
        console.log(e);
        navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
      }
    };

    getAuthenticator();
    getUserMetadata();
  }, []);

  const onInputCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

    // 最新のuser_metadataを取得
    const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/` });
    const usersUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user?.sub}`;

    const metadataResponse = await fetch(usersUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });


    if (metadataResponse.status !== 200) {
      // エラー
      const response = await metadataResponse.json();
      console.log(response);
      navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
      return;
    }

    // user_metadata取得成功
    const { user_metadata } = await metadataResponse.json();

    // MFA利用フラグをuser_metadataに保存
    const client_id = event.target.id.toString();
    user_metadata["enableMfa"] = user_metadata["enableMfa"] || {};
    user_metadata["enableMfa"][`${client_id}`] = event.target.checked;

    const updateParams = {
      "user_metadata": user_metadata
    };
    const updateResponse = await fetch(usersUrl, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updateParams)
    });

    // 結果取得
    if (updateResponse.status === 200) {
      const { user_metadata } = await updateResponse.json();
      setEnableMfaFlgList(user_metadata["enableMfa"] || {});
      console.log(user_metadata);
    } else {
      // その他のエラー
      const response = await updateResponse.json();
      console.log(response);
      navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
    }

    // MFA未登録の状態でトグルがONに変更された場合は自動的にメール認証に設定
    if (event.target.checked && authMethod === "未登録") {
      // メール認証設定API
      const enrollDummySmsApiUrl = `${process.env.REACT_APP_AUTH0_REST_API_INTERNET_URL}enrollDummySms`;

      // body
      const params = {
        "userid": user?.sub
  }

      // API呼び出し
      const enrollDummySmsResponse = await fetch(enrollDummySmsApiUrl, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params)
      });

      // 結果取得
      if (enrollDummySmsResponse.status === 200) {
        // メール認証設定成功
        setAuthMethod("SMS");
        setEmailMfaFlg(true);
    } else {
        console.log(enrollDummySmsResponse);
        // メール認証設定失敗
        const response = await enrollDummySmsResponse.json();
        console.log(response);
        navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
    }

    }
    console.log(event.target.checked);
  }

  // const enableMfaList = () => {
  //   let id = "";
  //   return (
  //     <div className="enable_mfa_client_list">
  //       {!isLoading &&
  //         ClientList.map((e, i) => (
  //           <div className="enable_mfa_client" key={i}>
  //             <div>{e.serviceName}</div>
  //             <div className="toggle_button">
  //               <input id={e.client_id} className="toggle_input" type='checkbox' defaultChecked={enableMfaFlgList?.[e.client_id]} onChange={onInputCheckboxChange} />
  //               <label htmlFor={e.client_id} className="toggle_label" />
  //             </div>
  //           </div>
  //         ))
  //       }
  //     </div>
  //   );
  // };

  return (
    <div className='content-wrapper'>
      <div className="screenTitle">
        <h1 style={{ fontSize: "1.9em" }}>二要素認証の設定</h1>
      </div>
      {/* <Tabs
        onSelect={(index) => setTabIndex(index)}
        selectedIndex={tabIndex}
        selectedTabClassName="base_tab--selected"
        selectedTabPanelClassName="react-tabs__tab-panel--selected active_tab_panel"
      >
        {isDesktop ? (
          <TabList className="tab_list">
            <Tab className="base_tab">二要素認証方法の設定</Tab>
            <Tab className="base_tab">二要素認証の有効化</Tab>
          </TabList>
        ) : (
          <TabList className="tab_list">
            <Tab className="base_tab">二要素認証<br/>方法の設定</Tab>
            <Tab className="base_tab">二要素認証の<br />有効化</Tab>
          </TabList>
        )
        }

        <TabPanel> */}
          現在の二要素認証方法：{authMethod === "SMS" && emailMfaFlg === true ? "メール" : authMethod}
          <div style={{ marginTop: "20px" }}>
            ※本設定の操作を途中で中断した場合、二要素認証が未設定となります。<br />
            ※二要素認証が未設定の場合でも、二要素認証が必要となるサービスでは、ログイン時にメールによる二要素認証が有効になります。
          </div>
          <div style={{ marginTop: "30px" }}>
            <isOpenResetMfaModalContext.Provider value={{ isOpenResetMfaModal, setIsOpenResetMfaModal }}>
              {authMethod === "未登録" ? (
                <Link className="button02 w-100" id="changeMfa" to="/mfa_token" state={{ from: "ResetMfa" }}>二要素認証を設定する</Link>
              ) : (
                <a className={authMethod !== '' ? 'button02 w-100' : 'button02 w-100 disabled-button'} id="changeMfa" onClick={() => setIsOpenResetMfaModal(true)}>二要素認証方法を変更する</a>
              )}
              <ResetMfaModalDialog />
            </isOpenResetMfaModalContext.Provider>
          </div>
        {/* </TabPanel>

        <TabPanel>
          <div>
            以下のサービスでは、二要素認証を有効にするかどうか任意で設定できます。有効にする場合はスイッチをON(緑)にしてください。<br />
            ※こちらに記載していないサービスでは、常に二要素認証が有効です。
          </div>
          {enableMfaList()}
        </TabPanel>

      </Tabs> */}
    </div>
  )
}

export default ResetMfa;