import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from 'react-hook-form';

const EnrollSms: React.FC = () => {
    const { user } = useAuth0();
    const navigate = useNavigate();

    // 入力チェック項目
    type Inputs = {
        phone: string;
    };
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (!errors.phone) {
            navigate('/sms_code_entry', { state: { phone: data.phone } });
        }
    };

    return (
        <div>
            <div className="screenTitle">
                <h1>電話番号の入力</h1>
            </div>
            <div>
                電話番号を以下に入力してください。<br />
                確認コードを記載したSMSがこの番号に送信されます。この確認コードを次の画面に入力してください。<br />
                ※ハイフン "-" は不要です。
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginTop: "30px" }}>
                    電話番号<br />
                    <input type="text" maxLength={100} {...register('phone', {
                        required: { value: true, message: 'このフィールドを入力してください。' },
                        minLength: { value: 10, message: '有効な電話番号ではありません。確認して再試行してください。' },
                        maxLength: { value: 11, message: '有効な電話番号ではありません。確認して再試行してください。' },
                        pattern: { value: /^(0{1}\d{9,10})$/, message: '電話番号に数字以外を含めることはできません。' }
                    })} placeholder="電話番号の入力" style={errors.phone ? { width: "300px", border: "solid red" } : { width: "300px" }} /><br />
                    {errors.phone && <span style={{ color: "red" }}>{errors.phone.message}</span>}
                </div>
                <div style={{ marginTop: "30px", display: "flex", flexDirection: "row" }} >
                    <button type="submit" className="button02" style={{ marginRight: "10px" }}>続ける</button>
                    <Link to="/select_mfa" className="button03" id="mfaCancel">別の方法で設定する</Link>
                </div>
            </form>
        </div>
    )
};

export default EnrollSms;