import React from "react";

export async function GetMfaToken(refreshToken: string) {
    try {
        // トークン取得
        const getTokenUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;
        const params = new URLSearchParams({
            "grant_type": "refresh_token",
            "client_id": process.env.REACT_APP_AUTH0_CLIENT_ID!,
            "refresh_token": refreshToken
        })
        const tokenResponse = await fetch(getTokenUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: params
        });
        const token = await tokenResponse.json();

        // リフレッシュトークンをstateに設定
        // const accessToken = token["access_token"];
        // const newRefreshToken = token["refresh_token"];

        //console.log("accessToken : " + accessToken);
        //return accessToken;
        return token;
    } catch (e) {
        // システムエラー画面へ遷移
        //navigate('/error');
        throw e;
    }
};
