import React, { useContext } from 'react';
import '../css/Header.css';
import { useAuth0 } from "@auth0/auth0-react";
import { requireAuthenticationContext } from '../App';
import Modal from "react-modal";
import { FaBars } from "react-icons/fa";
import { isOpenMenuContext } from '../App';
import ModalDialog from './ModalDialog';
import { IconContext } from "react-icons";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
import { useMediaQuery } from 'react-responsive';

Modal.setAppElement("#root");

// マイページ説明ダイアログの表示状態を管理するcontext
export const isOpenInfoModalContext = React.createContext({} as {
    isOpenInfoModal: boolean
    setIsOpenInfoModal: React.Dispatch<React.SetStateAction<boolean>>
});

const Header: React.FC = () => {
    const { user, isAuthenticated } = useAuth0();
    const requireAuthContext = useContext(requireAuthenticationContext);
    const { isOpenMenu, setIsOpenMenu } = React.useContext(isOpenMenuContext);
    const iconStyle: React.CSSProperties = { width: 28, height: 28, backgroundColor: 'transparent', color: '#fff' };

    if (requireAuthContext && !isAuthenticated) {
        return null;
    } else {
        return (
            <div className='header'>
                <div className="title">
                    <button className="menu-button" id="menuButton" onClick={() => setIsOpenMenu(!isOpenMenu)}>
                        <FaBars style={iconStyle} />
                    </button>
                    <div>eMAFF IDマイページ
                        <a id='info-tooltip' data-tooltip-content="eMAFF IDマイページでは、eMAFF IDに紐づくアカウント情報、利用可能なサービスの一覧、ログインの履歴を確認することができます。また、二要素認証に関する設定を行うこともできます。" style={{ paddingLeft: '0.5rem' }}>
                            <IconContext.Provider value={{ color: 'white', size: '0.8em' }}>
                                <AiFillQuestionCircle />
                            </IconContext.Provider>
                        </a>
                    </div>
                </div>
                <div className='mailaddress-header'>eMAFF ID : {user?.email}</div>
                <Tooltip
                    anchorId="info-tooltip"
                    style={{ backgroundColor: "white", color: "black", border: "solid 1px black", maxWidth: "20rem", zIndex: '99' }}
                />
            </div>
        );
    }
}

export default Header;