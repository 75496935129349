import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const Login: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const Login = async () => {
            console.log('ログイン処理開始');
            try {
                // const auth0 = new Auth0Client({
                //     domain: process.env.REACT_APP_AUTH0_DOMAIN!,
                //     client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
                //     redirect_uri: window.location.origin
                // });
                // auth0.loginWithRedirect({
                //     scope: 'openid profile read:current_user update:current_user_metadata'
                // });
                
                const query = new URLSearchParams(location.search);
                const from = query.get('departure_from');

                let authorizeUrlParams = new URLSearchParams();
                authorizeUrlParams.set('response_type', 'code');
                authorizeUrlParams.set('client_id', `${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
                authorizeUrlParams.set('audience', `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`);
                authorizeUrlParams.set('scope', 'openid profile read:current_user update:current_user_metadata');
                authorizeUrlParams.set('redirect_uri', window.location.origin);
                authorizeUrlParams.set('departure_from', `${from}`);
                const authorizeUrl = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/authorize?' + authorizeUrlParams.toString();
                window.location['assign'](authorizeUrl);

            } catch (e) {
                navigate('/error', { state: { name: 'ホーム' }, replace: true });
            }
        };
        Login();
    }, []);

    return null;
}

export default Login;