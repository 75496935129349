import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../css/Home.css';
import { Navigate, useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const navigate = useNavigate();

    // ユーザーのログイン方法（gBizID/eMAFF ID）を取得
    const isgBizID = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/isgBizIDLogin`] : false;

    // ユーザーの共通申請アカウントの権限を取得
    const iseMAFFAdmnin = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/iseMAFFAdmin`] : false;

    // 共通申請アカウントの有無を取得
    const emaffInfo = user ? user[`https://${process.env.REACT_APP_AUTH0_DOMAIN}/emaffInfo`] : {};
    const haseMAFFId = emaffInfo ? emaffInfo["eMAFFID"] ? true : false : false;

    return (
        <div>
            <div className="screenTitle">
                <h2></h2>
                <div className="logoutInfo">
                    ※ログアウトする場合は、eMAFF IDでログインしている各サービスの画面にて行ってください。
                </div>
            </div>
            <div className='home-main'>
                <div className="home-menu-wrapper">
                    <div className="home-menu" id="accountMenu" onClick={() => navigate('/account')}>
                        <img src="../image/icon_account.png" className="home-menu-icon" />
                        アカウント情報
                    </div>
                    <div className="home-menu" id="mfaMenu" onClick={() => navigate('/reset_mfa')}>
                        <img src="../image/icon_mfa.png" className="home-menu-icon" />
                        二要素認証の設定
                    </div>
                    <div className="home-menu" id="serviceMenu" onClick={() => navigate('/service')}>
                        <img src="../image/icon_service.png" className="home-menu-icon" />
                        連携サービス一覧
                    </div>
                    <div className="home-menu" id="activityMenu" onClick={() => navigate('/activity')}>
                        <img src="../image/icon_activity.png" className="home-menu-icon" />
                        最近のアクティビティ
                    </div>
                    {/* {isgBizID ? null :
                        <div className="home-menu" id="deleteMenu" onClick={() => navigate('/delete')}>
                            eMAFF ID削除
                            <img src="image/icon_delete.png" className="home-menu-icon" />
                        </div>
                    } */}
                </div>
            </div>
        </div>
    );
}

export default Home;