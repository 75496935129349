import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isMfaLoginContext } from '../App';
import { useAuth0 } from '@auth0/auth0-react';
import { useridContext } from '../App';
import { refreshTokenContext } from '../App';
import { GetMfaToken } from '../util/GetMfaToken';

const DeleteMfa: React.FC = () => {
    const { isMfaLogin, setIsMfaLogin } = React.useContext(isMfaLoginContext);
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const { userid, setUserid } = React.useContext(useridContext);
    const {refreshToken, setRefreshToken} = React.useContext(refreshTokenContext);

    useEffect(() => {
        const DeleteAllMfa = async () => {
            try {
                // const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access read:authenticators remove:authenticators" });
                const token = await GetMfaToken(refreshToken);
                const accessToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);
                if (!accessToken || accessToken === '') {
                    // アクセストークン取得に失敗した場合はエラー
                    setIsMfaLogin(false);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // List Authenticator
                const authenticatorUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/authenticators/`;

                const listResponse = await fetch(authenticatorUrl, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                // 取得に失敗した場合はエラー画面へ遷移
                if (listResponse.status !== 200) {
                    const response = await listResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // 二要素認証登録内容を取得
                const authenticators = await listResponse.json();
                console.log(authenticators);

                // すべての二要素認証登録を削除
                let deleteResponse;
                for (let i = 0; i < authenticators.length; i++) {
                    deleteResponse = await fetch(authenticatorUrl + authenticators[i]["id"], {
                        method: "DELETE",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    });
                    console.log(deleteResponse);
                }

                // user_metadataを取得
                let managementAccessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/api/v2/`, scope: "read:current_user update:current_user_metadata" });

                const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${userid}`;

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${managementAccessToken}`,
                    },
                });

                if (metadataResponse.status !== 200) {
                    // エラー
                    const response = await metadataResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // user_metadata取得成功
                const { user_metadata } = await metadataResponse.json();

                // email_mfaフラグ削除
                const updateUserUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${userid}`;

                user_metadata["email_mfa"] = null;

                const updateParams = {
                    "user_metadata": user_metadata
                };
                const updateResponse = await fetch(updateUserUrl, {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${managementAccessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(updateParams)
                });

                // 結果取得
                if (updateResponse.status !== 200) {
                    // その他のエラー
                    const response = await updateResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                navigate('/select_mfa');
            } catch (e) {
                console.log(e);
                navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
            }
        };
        DeleteAllMfa();
    }, []);

    return null;
}

export default DeleteMfa;