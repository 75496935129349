import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { isMfaLoginContext } from '../App';
import { useridContext } from '../App';
import { refreshTokenContext } from '../App';
import jwt from 'jwt-decode';

const MfaToken: React.FC = () => {
    const { isMfaLogin, setIsMfaLogin } = React.useContext(isMfaLoginContext);
    const { search } = useLocation();
    const navigate = useNavigate();
    const { getAccessTokenSilently, handleRedirectCallback, loginWithRedirect } = useAuth0();
    const location = useLocation();
    const { userid, setUserid } = React.useContext(useridContext);
    const { refreshToken, setRefreshToken } = React.useContext(refreshTokenContext);

    // 前画面から受け取るstate
    interface State {
        from: string;
    }

    useEffect(() => {
        const getToken = async () => {
            let from;
            if (location.state) {
                const state = location.state as State;
                from = state["from"];
            }
            if (from && from === 'ResetMfa') {
                // ResetMfaから遷移してきた場合
                if (!isMfaLogin) {
                    // authorize
                    let authorizeUrlParams = new URLSearchParams();
                    authorizeUrlParams.set('response_type', 'code');
                    authorizeUrlParams.set('client_id', `${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
                    authorizeUrlParams.set('audience', `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`);
                    authorizeUrlParams.set('scope', 'openid enroll offline_access read:authenticators remove:authenticators');
                    authorizeUrlParams.set('redirect_uri', `${window.location.origin}/mfa_token`);
                    authorizeUrlParams.set('skip_rule', 'true');
                    authorizeUrlParams.set('ext-mypage', 'ismypage');
                    const authorizeUrl = 'https://' + process.env.REACT_APP_AUTH0_DOMAIN + '/authorize?' + authorizeUrlParams.toString();
                    window.location.href = authorizeUrl;

                } else {
                    navigate('/delete_mfa');
                }
            } else {
                const query = new URLSearchParams(location.search);
                const code = query.get('code');
                if (code) {
                    // code exchange
                    const getTokenUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;
                    const params = new URLSearchParams({
                        "grant_type": "authorization_code",
                        "client_id": process.env.REACT_APP_AUTH0_CLIENT_ID!,
                        "redirect_uri": `${window.location.origin}/mfa_token`,
                        "code": code
                    })
                    const tokenResponse = await fetch(getTokenUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        },
                        body: params
                    });
                    const token = await tokenResponse.json();
                    const payLoad: any = jwt(token.access_token);
                    setRefreshToken(token.refresh_token);
                    setUserid(payLoad["sub"]);
                    setIsMfaLogin(true);
                    navigate('/delete_mfa');
                }
            }
        }
        getToken();
    }, []);

    return null;
};

export default MfaToken;