import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

interface PropsType {
    name: string;
};

const Error: React.FC = () => {
    const { user, isAuthenticated } = useAuth0();
    const prop = useLocation().state as PropsType;
    const content = () => {
        const name = prop?.name;

        if (name) {
            return (<>
                {name}において、何らかの理由によりエラーが発生しました。<br />
                {name}操作をやり直してください。
            </>
            )
        } else {
            return (<>
                何らかの理由によりエラーが発生しました。<br />
                操作をやり直してください。
            </>
            )
        }
    }

    const MoveButton = () => {
        if (isAuthenticated) {
            return (<Link to="/" className="button02 w-100">ホームへ戻る</Link>)
        } else {
            return (<Link to="/" className="button02 w-100">ログインへ</Link>)
        }
    }

    return (
        <div className='content-wrapper'>
            <div className='screenTitle'>
                <h1>システムエラー</h1>
            </div>
            <div>{content()}</div>
            <div style={{ marginTop: '20px' }}>{MoveButton()}</div>
        </div>
    )
}

export default Error;