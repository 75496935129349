import React, { useContext } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import '../css/Footer.css';
import { IoCaretForwardCircle } from 'react-icons/io5';
import { IconContext } from 'react-icons';
import { requireAuthenticationContext } from '../App';

const Footer: React.FC = () => {
    const { user, isAuthenticated } = useAuth0();
    const requireAuthContext = useContext(requireAuthenticationContext);

    if (requireAuthContext && !isAuthenticated) {
        return null;
    } else {
        return (
            <div className='footer'>
                <div className='footer-main'>
                    <div className='footer-link-wrapper'>
                        <IconContext.Provider value={{ size: "1.2rem" }}>
                            <div className='footer-link'>
                                <IoCaretForwardCircle />利用規約<a href='https://e.maff.go.jp/Terms' target='_blank' />
                            </div>
                            <div className='footer-link'>
                                <IoCaretForwardCircle />プライバシーポリシー<a href='https://e.maff.go.jp/PrivacyPolicy' target='_blank' />
                            </div>
                            <div className='footer-link'>
                                <IoCaretForwardCircle />お困りの場合<a href='https://e.maff.go.jp/Inquiry' target='_blank' />
                            </div>
                        </IconContext.Provider>
                    </div>
                    <div className='copyright'>
                        Copyright : Ministry of Agriculture, Forestry and Fisheries
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;