import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../css/SelectMfa.css';

const SelectMfa: React.FC = () => {
    const { user } = useAuth0();
    const ua = navigator.userAgent;
    let isMobile = false;
    if (/Android|iPhone|iPod|iPad/i.test(ua)) {
        isMobile = true;
    }
    return (
        <div>
            <div className="screenTitle">
                <h1>二要素認証方法の選択</h1>
            </div>
            <div className="mfa-option">
                {!isMobile && <>
                <Link className="mfa-option" to="/maff_app_install">
                    <img src="../image/logo.png" style={{ width: "30px" }} />
                    &emsp;MAFFアプリを使う
                </Link>
                <hr />
                </>}
                <Link className="mfa-option" to="/sms_entry">
                    <img src="../image/icon_sms.png" style={{ width: "30px" }} />
                    &emsp;コードをSMSで送信する
                </Link>
                <hr />
                <Link className="mfa-option" to="/mail_entry">
                    <img src="../image/icon_mail.png" style={{ width: "30px" }} />
                    &emsp;コードをメールで送信する 
                </Link>
            </div>
        </div>
    )
};

export default SelectMfa;