import React, { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { metaDataContext } from '../App';

// ユーザーメータデータ取得処理用コンポーネント
const UserMetaData: React.FC = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { metaData, setMetaData } = useContext(metaDataContext);
    const navigate = useNavigate();
    const navigateToError = () => {
        navigate('/error', { state: { name: 'ホーム' }, replace: true });
    };

    useEffect(() => {
        const getUserMetaData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN!}/api/v2/users/${user?.sub}`;
                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                const { user_metadata } = await metadataResponse.json();
                if (user_metadata) {
                    setMetaData(user_metadata);
                } else {
                    navigateToError();
                }
            } catch (e) {
                navigateToError();
            }
        }
        if (user) {
            if (!metaData || (metaData && Object.keys(metaData).length == 0)) {
                getUserMetaData();
            }
        }
    }, [getAccessTokenSilently, user?.sub]);

    return null;
}

export default UserMetaData;