import React from 'react';
import '../css/Loader.css';

const Loader: React.FC = () => {
    const loadingImg = "https://cdn.auth0.com/blog/hello-auth0/loader.svg";

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }} >
            <div className="loader"></div>
        </div>
    );
};

export default Loader;