import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { isMfaLoginContext } from '../App';
import { refreshTokenContext } from '../App';
import { GetMfaToken } from '../util/GetMfaToken';
import '../css/VerifySms.css';

const VerifySms: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const { isMfaLogin, setIsMfaLogin } = React.useContext(isMfaLoginContext);
    const [oobCode, setOobCode] = React.useState('');
    const [startTime, setStartTime] = React.useState(Date.now);
    const [sendTime, setSendTime] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const {refreshToken, setRefreshToken} = React.useContext(refreshTokenContext);

    // 前画面から受け取るstate
    interface State {
        phone: string;
    }
    const { phone } = location.state as State;

    // 入力チェック項目
    type Inputs = {
        code: string;
    };
    const { register, handleSubmit, setError, formState: { errors } } = useForm<Inputs>();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (!errors.code) {
            try {
                // const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access enroll" });
                const token = await GetMfaToken(refreshToken);
                const accessToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);

                if (!accessToken || accessToken === '') {
                    // アクセストークン取得に失敗した場合はエラー
                    setIsMfaLogin(false);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // 確認コード送信
                // Verify OOB
                const verifyCodeUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`;

                // body
                const params = {
                    "grant_type": "http://auth0.com/oauth/grant-type/mfa-oob",
                    "client_id": `${process.env.REACT_APP_AUTH0_CLIENT_ID!}`,
                    "mfa_token": accessToken,
                    "oob_code": oobCode,
                    "binding_code": data.code
                }

                // API呼び出し
                const now = Date.now;
                const addResponse = await fetch(verifyCodeUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(params)
                });
                // 結果取得
                if (addResponse.status === 200) {
                    // 登録成功
                    navigate('/success_register_mfa', { state: { type: 'sms' } });
                } else {
                    // 登録失敗
                    const response = await addResponse.json();
                    console.log(response);
                    if (response["error"] === "invalid_grant") {
                        // コード間違い/期限切れ
                        let limitDate = new Date(startTime);
                        limitDate.setMinutes(limitDate.getMinutes() + 5);
                        let now = new Date();
                        if (now > limitDate) {
                            // startTimeから5分経過していたら時間切れ
                            setError("code", { type: "validate", message: "確認コードの有効期限が切れています。「再送信」をクリックして再度確認コードを送信してください。" }, { shouldFocus: true });
                        } else {
                            // 時間内ならコード間違い
                            setError("code", { type: "validate", message: "入力されたコードは無効です。" }, { shouldFocus: true });
                        }
                    } else if (addResponse.status === 429) {
                        // 失敗回数上限エラー
                        setError("code", { type: "validate", message: "コードの失敗回数が多すぎます。数分待ってから再試行してください。" }, { shouldFocus: true });
                    } else if (response["error"] === "expired_token") {
                        // oobコード有効期限切れ
                        setError("code", { type: "validate", message: "確認コードの有効期限が切れています。「再送信」をクリックして再度確認コードを送信してください。" }, { shouldFocus: true });
                    } else {
                        // その他のエラー
                        navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    }
                }
            } catch (e) {
                console.log(e);
                navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
            }
        }
    };

    useEffect(() => {
        const enrollSmsAuthenticator = async () => {
            try {
                // const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access enroll" });
                const token = await GetMfaToken(refreshToken);
                const accessToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);

                if (!accessToken || accessToken === '') {
                    // アクセストークン取得に失敗した場合はエラー
                    setIsMfaLogin(false);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // SMS認証の登録開始
                // Add Authenticator
                const addAuthUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/associate/`;

                // body
                const params = {
                    "client_id": `${process.env.REACT_APP_AUTH0_CLIENT_ID!}`,
                    "authenticator_types": ["oob"],
                    "oob_channels": ["sms"],
                    "phone_number": `+81${phone.substring(1)}`
                }

                // API呼び出し
                const addResponse = await fetch(addAuthUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(params)
                });
                // 結果取得
                if (addResponse.status === 200) {
                    // SMS送信成功
                    const response = await addResponse.json();
                    console.log(response);
                    setOobCode(response["oob_code"]);
                    setStartTime(Date.now);
                } else if (addResponse.status === 429) {
                    // SMS送信数上限エラー
                    setError("code", { type: "validate", message: "一時間当たりのSMSの上限数を超過しました。数分時間を置いて再試行してください。" }, { shouldFocus: true });
                } else {
                    // その他のエラー
                    const response = await addResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                }
            } catch (e) {
                console.log(e);
                navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
            }
        };
        enrollSmsAuthenticator();
    }, [sendTime]);

    return (
        <div>
            <div className="screenTitle">
                <h1>確認コードの入力</h1>
            </div>
            <div></div>
            <div>
                次の番号に送信された確認コードを入力してください。<br />
                確認コードが届かない場合は、「再送信」をクリックしてください。<br />
            </div>
            <div className="sms-code-wrapper">
                <div>電話番号：{phone}</div>
                <Link to="/sms_entry" className="button01" style={{ marginLeft: "10px" }}>変更</Link>
                <button type="button" onClick={() => { setSendTime(Date.now) }} className="button01" style={{ marginLeft: "10px" }}>再送信</button>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="text" maxLength={100} {...register('code', {
                    required: { value: true, message: 'このフィールドを入力してください。' },
                    pattern: { value: /^\d{6}$/, message: '入力されたコードは無効です。' }
                })} placeholder="確認コードの入力" style={errors.code ? { width: "300px", border: "solid red" } : { width: "300px" }} /><br />
                {errors.code && <span style={{ color: "red" }}>{errors.code.message}</span>}
                <div style={{ marginTop: "30px", display: "flex", flexDirection: "row" }}>
                    <button type="submit" className="button02" style={{ marginRight: "10px" }}>続ける</button>
                    <Link to="/select_mfa" className="button03" id="mfaCancel">別の方法で設定する</Link>
                </div>
            </form>
        </div>
    )
}

export default VerifySms;