import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

const Logout: React.FC = () => {
    const isDesktop = useMediaQuery({ minWidth: 760 })

    return (
        <div className='content-wrapper'>
            <div className="screenTitle">
                <h1>ログアウト</h1>
            </div>
            <div>
                ログアウトしました。<br />
            </div>
            <div style={isDesktop ? { marginTop: "30px", display: "flex", justifyContent: "left" }: { marginTop: "30px", display: "flex", justifyContent: "center" }}>
                <Link to="/" className="button02">ログインへ</Link>
            </div>
            <div style={{ marginTop: "10px" }}>
            </div>
        </div>
    );
}

export default Logout;