import React, { ComponentType, CSSProperties, useEffect, useState } from 'react';
import { Routes, Route, useNavigate, matchPath, useLocation } from "react-router-dom";
import { useAuth0, AppState, Auth0Provider, withAuthenticationRequired, User } from "@auth0/auth0-react";
import './App.css';
import Loader from './component/Loader';
import Header from './component/Header';
import Footer from './component/Footer';
import Menu from './component/Menu';
import Home from './component/Home';
import Account from './component/Account';
import VerifyMailChange from './component/VerifyMailChange';
import SuccessMailChange from './component/SuccessMailChange';
import ResetMfa from './component/ResetMfa';
import DeleteMfa from './component/DeleteMfa';
import SelectMfa from './component/SelectMfa';
import InstallMaffApp from './component/InstallMaffApp';
import RegisterMaffApp from './component/RegisterMaffApp';
import CompleteMfa from './component/CompleteMfa';
import EnrollSms from './component/EnrollSms';
import VerifySms from './component/VerifySms';
import EnrollDummySms from './component/EnrollDummySms';
import Service from './component/Service';
import Delete from './component/Delete';
import UserMetaData from './component/UserMetaData';
import Error from './component/Error';
import Logout from './component/Logout';
import MfaToken from './component/MfaToken';
import Activity from './component/Activity';
import Login from './component/Login';
import NotFound from './component/NotFound';

// Auth0接続情報
const providerConfig = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID!,
  audience: "https://" + process.env.REACT_APP_AUTH0_TENANT_DOMAIN! + "/api/v2/",
  redirectUri: window.location.origin,
  useRefreshTokens: true,
};

interface ProtectedRouteProps {
  component: ComponentType;
}

// 認証済みの場合のみ表示するコンポーネント
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component, }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loader />,
  });
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={providerConfig.domain}
      scope="openid profile read:current_user update:current_user_metadata"
      clientId={providerConfig.clientId}
      audience={providerConfig.audience}
      onRedirectCallback={onRedirectCallback}
      redirectUri={providerConfig.redirectUri}
      useRefreshTokens={true}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

// リフレッシュトークンを保持するcontext
export const refreshTokenContext = React.createContext({} as {
  refreshToken: string
  setRefreshToken: React.Dispatch<React.SetStateAction<string>>
});

// ユーザーIDを保持するcontext
export const useridContext = React.createContext({} as {
  userid: string
  setUserid: React.Dispatch<React.SetStateAction<string>>
});
// 認証済みか否かの状態を管理するcontext
export const requireAuthenticationContext = React.createContext<boolean>(false);
// スマートフォンレイアウト時のメニュー表示状態を管理するcontext
export const isOpenMenuContext = React.createContext({} as {
  isOpenMenu: boolean
  setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
});
// MFAトークン取得用のログイン処理が実行済みかを管理するcontext
export const isMfaLoginContext = React.createContext({} as {
  isMfaLogin: boolean
  setIsMfaLogin: React.Dispatch<React.SetStateAction<boolean>>
});
// 二要素認証の新規登録/変更を区別するためのフラグを管理するcontext
export const isModifyMFAContext = React.createContext({} as {
  isModifyMFA: boolean
  setIsModifyMFA: React.Dispatch<React.SetStateAction<boolean>>
});

export const metaDataContext = React.createContext({} as {
  metaData: metaData
  setMetaData: React.Dispatch<React.SetStateAction<metaData>>
});

export type dataValues = {
  [key: string]: [value: string | object | Array<object>];
}
export type metaData = {
  [key: string]: dataValues;
};

const menuStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "fixed",
  top: 70,
  left: 0,
  backgroundColor: "#d9d9d9bf",
  zIndex: 90
}
const noneStyle: React.CSSProperties = {
  display: "none"
}

function App() {
  const { pathname } = useLocation();
  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const [isMfaLogin, setIsMfaLogin] = React.useState(false);
  const [metaData, setMetaData] = React.useState({} as metaData);
  const [userid, setUserid] = React.useState('');
  const [refreshToken, setRefreshToken] = React.useState('');
  const [isModifyMFA, setIsModifyMFA] = React.useState(true);

  // 認証の必要なページか否かを判定する処理
  function RequireAuthentication() {
    return !["/mfa_token", "/verify_change_email", "/success_change_email", "/mfa_token", "/delete_mfa", "/maff_app_register", "/sms_entry", "/sms_code_entry", "/mail_entry", "/logout", "/error"].find((path) => !!matchPath(path, pathname));
  }

  let appStyle: CSSProperties;
  const ua = navigator.userAgent;
  let isMobile = false;
  if (/Android|iPhone|iPod|iPad/i.test(ua)) {
      isMobile = true;
  }

  if (isMobile) {
    appStyle = {
    height: window.innerHeight,
    width: "100vw",
    display: "flex",
    flexDirection: "column"
  };
  } else {
    appStyle = {
      height: "100vh",
      width: "100vw",
      display: "flex",
      flexDirection: "column"
    };
  }

  return (
    <div className="App" style={appStyle}>
      <Auth0ProviderWithRedirectCallback>
        <refreshTokenContext.Provider value={{ refreshToken, setRefreshToken }}>
          <useridContext.Provider value={{ userid, setUserid }}>
            <requireAuthenticationContext.Provider value={RequireAuthentication()}>
              <metaDataContext.Provider value={{ metaData, setMetaData }}>
                <isOpenMenuContext.Provider value={{ isOpenMenu, setIsOpenMenu }}>
                  <isMfaLoginContext.Provider value={{ isMfaLogin, setIsMfaLogin }}>
                    <isModifyMFAContext.Provider value={{ isModifyMFA, setIsModifyMFA }}>
                      <Header />
                      <UserMetaData />
                      <div className='contents-area'>
                        <div className='main-wrapper'>
                          <Menu />
                          <div className='main'>
                            <Routes>
                              <Route path={`/`} element={<ProtectedRoute component={Home} />} />
                              <Route path={`/account`} element={<ProtectedRoute component={Account} />} />
                              {/* <Route path={`/verify_change_email`} element={<VerifyMailChange />} />
                              <Route path={`/success_change_email`} element={<SuccessMailChange />} /> */}
                              <Route path={`/reset_mfa`} element={<ProtectedRoute component={ResetMfa} />} />
                              <Route path={`/delete_mfa`} element={<DeleteMfa />} />
                              <Route path={`/select_mfa`} element={<ProtectedRoute component={SelectMfa} />} />
                              <Route path={`/maff_app_install`} element={<ProtectedRoute component={InstallMaffApp} />} />
                              <Route path={`/maff_app_register`} element={<RegisterMaffApp />} />
                              <Route path={`/success_register_mfa`} element={<ProtectedRoute component={CompleteMfa} />} />
                              <Route path={`/sms_entry`} element={<EnrollSms />} />
                              <Route path={`/sms_code_entry`} element={<VerifySms />} />
                              <Route path={`/mail_entry`} element={<EnrollDummySms />} />
                              <Route path={`/mfa_token`} element={<MfaToken />} />
                              <Route path={`/service`} element={<ProtectedRoute component={Service} />} />
                              {/* <Route path={`/delete`} element={<ProtectedRoute component={Delete} />} /> */}
                              <Route path={`/activity`} element={<ProtectedRoute component={Activity} />} />
                              <Route path={`/logout`} element={<Logout />} />
                              <Route path={`/error`} element={<Error />} />
                              <Route path={`/login`} element={<Login />} />
                              <Route path={`*`} element={<ProtectedRoute component={NotFound} />} />
                            </Routes>
                          </div>
                          <div style={isOpenMenu ? menuStyle : noneStyle} onClick={() => setIsOpenMenu(false)}>
                          </div>
                        </div>
                        <Footer />
                      </div>
                    </isModifyMFAContext.Provider>
                  </isMfaLoginContext.Provider>
                </isOpenMenuContext.Provider>
              </metaDataContext.Provider>
            </requireAuthenticationContext.Provider>
          </useridContext.Provider>
        </refreshTokenContext.Provider>
      </Auth0ProviderWithRedirectCallback>
    </div>
  );
}

export default App;
