/*eslint-disable*/
import React, { useEffect, useState, useContext } from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css';
import '../css/Account.css';
import prefectures from './Prefectures';
import { metaData, dataValues, metaDataContext } from '../App';
import { Tooltip } from 'react-tooltip';
import { AiFillQuestionCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

type CustomLinkProps = {
    children: React.ReactNode,
    to: string
}

function CustomLink({ children, to }: CustomLinkProps) {
    let resolved = useResolvedPath(to);
    let match = useMatch({
        path: resolved.pathname,
        end: true,
    });
    return (
        <Link className='button-change' to={to}>
            {children}
        </Link>
    );
}

const Account: React.FC = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { metaData, setMetaData } = useContext(metaDataContext);
    const [userMetaData, setUserMetadata] = useState<metaData>();
    const [emaffInfo, setEmaffInfo] = useState<dataValues>();
    const [tempEmaffInfo, setTempEmaffInfo] = useState(Array<dataValues>);
    const [selectedTempEmaffInfo, setSelectedTempEmaffInfo] = useState<dataValues>();
    const [gbizInfo, setgbizInfo] = useState<dataValues>();
    const [selectedOption, setSelectedOption] = useState<string>();
    const [emaffChatName, setEmaffChatName] = useState<string>('');
    const [userPropContent, setUserPropContent] = useState<string>('');
    const [organPropContent, setOrganPropContent] = useState<string>('');

    const selectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value;
        setSelectedOption(value);
        const result = tempEmaffInfo.filter(e => e.eMAFFID.toString() === value);
        if (result.length > 0) {
            setSelectedTempEmaffInfo(result[0]);
        }
    }

    function getEmaffChatName() {
        const axios = require('axios').default;
        const url = process.env.REACT_APP_AUTH0_REST_API_INTERNET_URL+'getSlackDispName';
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const data = { 'email': user?.email };

        axios.post(url, data, config)
            .then(function (response: any) {
                if (response.data.length > 0) {
                    const displayName = response.data[0].displayName;
                    setEmaffChatName(displayName);
                }
            })
            .catch(function (error: Error) {
                console.log(error);
            })
    }

    useEffect(() => {
        if (metaData) {
            setUserMetadata(metaData);
            setgbizInfo(metaData.gbizInfo);
            setEmaffInfo(metaData.emaffInfo);
            setTempEmaffInfo(metaData.all_emaffInfo as any);
            if(metaData.all_emaffInfo){
                setSelectedTempEmaffInfo(metaData.all_emaffInfo[0] as any);
            }
            getEmaffChatName();
        }

    }, [user?.sub]);

    const renderAccountInfo = () => {
        return (
            <>
                <div className='screenTitle'>
                    <h1>アカウント情報</h1>
                </div>
                <div>
                    ※アカウント情報にはログイン時の情報が表示されます。<br />
                    情報を更新するためには、再度ログインが必要です。
                </div>
                <div>
                    <div className='subtitle'>
                        メールアドレス
                    </div>
                    <table className='userinfo'>
                        <tbody>
                            <tr>
                                <th className='userinfo-name'>メールアドレス</th>
                                <td className='userinfo-value'>
                                    <div className='mailaddress-wrapper'>
                                        <div>{user?.email}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {renderUserPropertyInfo()}
                    {renderOrganizationInfo()}
                </div>
            </>
        )
    }

    const renderUserPropertyInfo = () => {
        return (<>
            <div className='subtitle'> ユーザ属性情報
                {renderUserInfoTooltipContent()}
            </div>
            <div style={{ display: 'block' }}>
                <table className='userinfo'>
                    <tbody>
                        {renderOldEmaffId()}
                        {renderUserName()}
                        {renderUserNameKana()}
                        {renderUserKubun()}
                        {renderUserGbizInfo()}
                        {renderShinseishaDetailInfo()}
                        {renderExaminerKubun()}
                        {renderOrganKubun()}
                    </tbody>
                </table>
            </div>
        </>)
    }

    const renderOrganizationInfo = () => {
        let OrganTitleName = '';

        if ((!selectedTempEmaffInfo?.組織コード && selectedTempEmaffInfo?.ユーザ区分?.toString() === '申請者' && gbizInfo)
            || (!selectedTempEmaffInfo && gbizInfo)) {
            OrganTitleName = '経営体情報';
        }
        else {
            OrganTitleName = '所属組織情報';
        }

        return (<>
            <div className='subtitle'>
                {OrganTitleName}
                {renderOrganInfoTooltipContent()}
            </div>
            <table className='userinfo'>
                <tbody>
                    {renderEMAFFType()}
                    {renderCorpKubunAndNo()}
                    {renderCorpName()}
                    {renderUserOrganGbizInfo()}
                    {renderOrganCodeAndName()}
                </tbody>
            </table>
        </>)
    }

    const renderOldEmaffId = () => {
        let dispFlg: boolean = false;

        if (!((!tempEmaffInfo && !gbizInfo) || (!tempEmaffInfo && gbizInfo))) {
            if (tempEmaffInfo.length > 0) {
                if (tempEmaffInfo.length == 1) {
                    return (
                        <>
                            <tr>
                                <th className='userinfo-name'>旧eMAFF ID</th>
                                <td className='userinfo-value'>{selectedTempEmaffInfo?.eMAFFID.toString()}</td>
                            </tr>
                        </>
                    )
                } else {
                    return (
                        <>
                            <tr>
                                <th className='userinfo-name'>旧eMAFF ID</th>
                                <td className='userinfo-value'>
                                    <select title='select' style={{width:'100%', maxWidth:'280px'}} value={selectedOption} onChange={selectChange}>
                                        {
                                            tempEmaffInfo.map((e, i) => (
                                                <option key={i} >{e.eMAFFID.toString()}</option>
                                            ))
                                        }
                                    </select>
                                </td>
                            </tr>
                        </>
                    )
                }
            }
        }
    }

    const renderUserName = () => {
        let dispFlg: boolean = false;

        if (!(!selectedTempEmaffInfo && !gbizInfo)) {
            let name = '';
            if (selectedTempEmaffInfo) {
                name = selectedTempEmaffInfo.氏名?.toString();
            }
            else if (gbizInfo) {
                name = gbizInfo.user_last_nm?.toString() + gbizInfo.user_first_nm?.toString();
            }
            if (name) {
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>氏名</th>
                            <td className='userinfo-value'>{name}</td>
                        </tr>
                    </>
                )
            }
        }
    }

    const renderUserNameKana = () => {
        let nameKana: string;
        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者') {
            if (selectedTempEmaffInfo.組織コード.toString() !== '') {
                return ''
            }
            else if (gbizInfo) {
                nameKana = gbizInfo.user_last_nm_kana?.toString() + gbizInfo.user_first_nm_kana?.toString();
                if (nameKana) {
                    return (
                        <>
                            <tr>
                                <th className='userinfo-name'>氏名フリガナ</th>
                                <td className='userinfo-value'>{nameKana}</td>
                            </tr>
                        </>
                    )
                }
            }
        } else if(!selectedTempEmaffInfo && gbizInfo){
            nameKana = gbizInfo.user_last_nm_kana?.toString() + gbizInfo.user_first_nm_kana?.toString();
            if (nameKana) {
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>氏名フリガナ</th>
                            <td className='userinfo-value'>{nameKana}</td>
                        </tr>
                    </>
                )
            }
        }
    }

    const renderUserKubun = () => {
        if (!((!selectedTempEmaffInfo && !gbizInfo) || (!selectedTempEmaffInfo && gbizInfo))) {
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>ユーザ区分</th>
                        <td className='userinfo-value'>{selectedTempEmaffInfo?.ユーザ区分?.toString()}</td>
                    </tr>
                </>
            )
        }
    }

    const renderUserGbizInfo = () => {
        const postReg = new RegExp("^([0-9]{3}-[0-9]{4})$");
        const birthReg = new RegExp("^(\d{4}\/(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01]))$");

        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者') {
            if (selectedTempEmaffInfo.組織コード.toString() !== '') {
                return ''
            }
            else if (gbizInfo) {
                const prefecture = prefectures[gbizInfo.user_prefecture_name?.toString()];
                const address = prefecture
                    + gbizInfo.user_address1?.toString()
                    + gbizInfo.user_address2?.toString()
                    + gbizInfo.user_address3?.toString();
                const telNo = gbizInfo.user_tel_no_contact?.toString();

                let postCode = gbizInfo.user_post_code?.toString();
                if (postCode) {
                    if (!postReg.test(postCode)) {
                        if (postCode.length == 7) {
                            postCode = `${postCode.substring(0, 3)}-${postCode.substring(3)}`;
                        }
                    }
                }

                let birthDay = gbizInfo.user_birthday_ymd?.toString();
                if (birthDay) {
                    if (!birthReg.test(birthDay)) {
                        if (birthDay.length == 8) {
                            birthDay = `${birthDay.substring(0, 4)}/${birthDay.substring(4, 6)}/${birthDay.substring(6)}`;
                        }
                    }
                }

                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>郵便番号</th>
                            <td className='userinfo-value'>{postCode}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>住所</th>
                            <td className='userinfo-value'>{address}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>電話番号</th>
                            <td className='userinfo-value'>{telNo}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>生年月日</th>
                            <td className='userinfo-value'>{birthDay}</td>
                        </tr>
                    </>
                )
            }
        } else if(!selectedTempEmaffInfo && gbizInfo){
            const accountType = gbizInfo.account_type?.toString();
            let accountTypeName;
            switch (accountType) {
                case '1':
                    accountTypeName = 'gBizID エントリー';
                    break;
                case '2':
                    accountTypeName = 'gBizID プライム';
                    break;
                case '3':
                    accountTypeName = 'gBizID メンバー';
                    break;
            }

            const prefecture = prefectures[gbizInfo.user_prefecture_name?.toString()];
            const address = prefecture
                + gbizInfo.user_address1?.toString()
                + gbizInfo.user_address2?.toString()
                + gbizInfo.user_address3?.toString();
            const telNo = gbizInfo.user_tel_no_contact?.toString();

            let postCode = gbizInfo.user_post_code?.toString();
            if (postCode) {
                if (!postReg.test(postCode)) {
                    if (postCode.length == 7) {
                        postCode = `${postCode.substring(0, 3)}-${postCode.substring(3)}`;
                    }
                }
            }
            
            let birthDay = gbizInfo.user_birthday_ymd?.toString();
            if (birthDay) {
                if (!birthReg.test(birthDay)) {
                    if (birthDay.length == 8) {
                        birthDay = `${birthDay.substring(0, 4)}/${birthDay.substring(4, 6)}/${birthDay.substring(6)}`;
                    }
                }
            }

            return (
                <>
                    <tr>
                        <th className='userinfo-name'>アカウント種別</th>
                        <td className='userinfo-value'>{accountTypeName}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>郵便番号</th>
                        <td className='userinfo-value'>{postCode}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>住所</th>
                        <td className='userinfo-value'>{address}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>電話番号</th>
                        <td className='userinfo-value'>{telNo}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>生年月日</th>
                        <td className='userinfo-value'>{birthDay}</td>
                    </tr>
                </>
            )
        }
    }

    const renderExaminerKubun = () => {
        if (selectedTempEmaffInfo?.審査者区分) {
            const kubun = selectedTempEmaffInfo.審査者区分.toString();
            let kubunName;
            switch (kubun) {
                case '0':
                    kubunName = '行政事務等'
                    break;
                case '1':
                    kubunName = '補助者'
                    break;
                case '2':
                    kubunName = '一般'
                    break;
            }

            if (kubunName) {
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>審査者区分</th>
                            <td className='userinfo-value'>{kubunName}</td>
                        </tr>
                    </>
                )
            }
        }
    }

    const renderOrganKubun = () => {
        if (selectedTempEmaffInfo?.組織管理者区分) {
            const kubun = selectedTempEmaffInfo.組織管理者区分.toString();
            let kubunName;
            switch (kubun) {
                case '0':
                    kubunName = '公的機関等向け'
                    break;
                case '1':
                    kubunName = '一般機関向け'
                    break;
            }

            if (kubunName) {
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>組織管理者区分</th>
                            <td className='userinfo-value'>{kubunName}</td>
                        </tr>
                    </>
                )
            }
        }
    }

    // 申請者詳細情報の表示
    const renderShinseishaDetailInfo = () => {
        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者') {
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>性別</th>
                        <td className='userinfo-value'>{selectedTempEmaffInfo?.性別?.toString()}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>本人確認状況</th>
                        <td className='userinfo-value'>{selectedTempEmaffInfo?.本人確認状況?.toString()}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>構成員区分</th>
                        <td className='userinfo-value'>{selectedTempEmaffInfo?.構成員区分?.toString()}</td>
                    </tr>
                </>
            )

        }
    }

    const renderEMAFFType = () => {
        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者' && selectedTempEmaffInfo?.eMAFF種別) {
            const kubun = selectedTempEmaffInfo?.eMAFF種別.toString();
            let kubunName;
            switch (kubun) {
                case '0':
                    kubunName = 'eMAFF未活性'
                    break;
                case '1':
                    kubunName = 'eMAFFエントリー'
                    break;
                case '2':
                    kubunName = 'eMAFFプライム'
                    break;
            }

            if (kubunName) {
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>eMAFF種別</th>
                            <td className='userinfo-value'>{kubunName}</td>
                        </tr>
                    </>
                )
            }
        }
    }

    const renderCorpKubunAndNo = () => {
        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者') {
            if(selectedTempEmaffInfo.組織コード.toString() !== '') {
                return ''
            }
            else if (gbizInfo) {
                const kubun = gbizInfo.corp_type?.toString();
                let kubunName;
                let corporateNumber = gbizInfo.corporate_number?.toString();
                switch (kubun) {
                    case '1':
                        kubunName = '法人'
                        break;
                    case '2':
                        kubunName = '個人事業主'
                        break;
                }
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>事業形態</th>
                            <td className='userinfo-value'>{kubunName}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>法人番号／個人事業主管理番号</th>
                            <td className='userinfo-value'>{corporateNumber}</td>
                        </tr>
                    </>
                )
            }
        } else if(!selectedTempEmaffInfo && gbizInfo){
            const kubun = gbizInfo.corp_type?.toString();
            let kubunName;
            let corporateNumber = gbizInfo.corporate_number?.toString();
            switch (kubun) {
                case '1':
                    kubunName = '法人'
                    break;
                case '2':
                    kubunName = '個人事業主'
                    break;
            }
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>事業形態</th>
                        <td className='userinfo-value'>{kubunName}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>法人番号／個人事業主管理番号</th>
                        <td className='userinfo-value'>{corporateNumber}</td>
                    </tr>
                </>
            )
        }
    }

    const renderCorpName = () => {
        let corpName = '';

        if (selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者') {
           if(!selectedTempEmaffInfo?.アカウント種別) {
                corpName = selectedTempEmaffInfo?.['法人名/屋号']?.toString();
            } else if (gbizInfo) {
                corpName = gbizInfo?.name?.toString();
            }
        } else if(!selectedTempEmaffInfo && gbizInfo) {
            corpName = gbizInfo?.name?.toString();
        }
        
        if (corpName) {
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>法人名／屋号</th>
                        <td className='userinfo-value'>{corpName}</td>
                    </tr>
                </>
            )
        }
    }

    const renderUserOrganGbizInfo = () => {
        if(selectedTempEmaffInfo?.ユーザ区分?.toString() == '申請者'){
            if(selectedTempEmaffInfo.組織コード.toString() !== '') {
                return ''
            }
            else if (gbizInfo) {
                const prefecture = prefectures[gbizInfo.prefecture_name?.toString()];
                const address = prefecture
                    + gbizInfo.address1?.toString()
                    + gbizInfo.address2?.toString();
                const repName = gbizInfo.rep_last_nm?.toString() + gbizInfo.rep_first_nm?.toString();
                const repNameKana = gbizInfo.rep_last_nm_kana?.toString() + gbizInfo.rep_first_nm_kana?.toString();
                const departName = gbizInfo.user_department?.toString();
                return (
                    <>
                        <tr>
                            <th className='userinfo-name'>所在地</th>
                            <td className='userinfo-value'>{address}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>代表者名</th>
                            <td className='userinfo-value'>{repName}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>代表者名フリガナ</th>
                            <td className='userinfo-value'>{repNameKana}</td>
                        </tr>
                        <tr>
                            <th className='userinfo-name'>部署名</th>
                            <td className='userinfo-value'>{departName}</td>
                        </tr>
                    </>
                )
            }
        } else if(!selectedTempEmaffInfo && gbizInfo){
            const prefecture = prefectures[gbizInfo.prefecture_name?.toString()];
            const address = prefecture
                + gbizInfo.address1?.toString()
                + gbizInfo.address2?.toString();
            const repName = gbizInfo.rep_last_nm?.toString() + gbizInfo.rep_first_nm?.toString();
            const repNameKana = gbizInfo.rep_last_nm_kana?.toString() + gbizInfo.rep_first_nm_kana?.toString();
            const departName = gbizInfo.user_department?.toString();
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>所在地</th>
                        <td className='userinfo-value'>{address}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>代表者名</th>
                        <td className='userinfo-value'>{repName}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>代表者名フリガナ</th>
                        <td className='userinfo-value'>{repNameKana}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>部署名</th>
                        <td className='userinfo-value'>{departName}</td>
                    </tr>
                </>
            )
        }
    }

    const renderOrganCodeAndName = () => {
        if(selectedTempEmaffInfo && selectedTempEmaffInfo.組織コード){
            const organCode = selectedTempEmaffInfo?.組織コード?.toString();
            const organName = selectedTempEmaffInfo?.所属組織名?.toString();
            return (
                <>
                    <tr>
                        <th className='userinfo-name'>組織コード</th>
                        <td className='userinfo-value'>{organCode}</td>
                    </tr>
                    <tr>
                        <th className='userinfo-name'>所属組織名</th>
                        <td className='userinfo-value'>{organName}</td>
                    </tr>
                </>
            )
        }
        else {
            return ''
        }
    }

    const renderUserInfoTooltipContent = () => {
        let tooltipContent = '';

        if (tempEmaffInfo?.length === 0 && gbizInfo) {
            tooltipContent = 'gbizIDに登録されている情報が表示されます。';
        } else {
            tooltipContent = '農林水産省共通申請サービスに登録されている情報が表示されます。';
        }

        return (
            <>
                <a id='info-tooltip-account1'>
                    <IconContext.Provider value={{ color: '#2B7D54', size: '1em', className: 'icon-vertical-account' }}>
                        <AiFillQuestionCircle />
                    </IconContext.Provider>
                </a>
                <Tooltip
                    anchorId="info-tooltip-account1"
                    style={{ backgroundColor: "#2B7D54", color: "white", maxWidth: "20rem" }}
                >{tooltipContent}</Tooltip>
            </>
        )
    }

    const renderOrganInfoTooltipContent = () => {
        let tooltipContent = '';

        if (tempEmaffInfo?.length === 0 && gbizInfo) {
            tooltipContent = 'gbizIDに登録されている情報が表示されます。';
        } else {
            tooltipContent = '農林水産省共通申請サービスに登録されている情報が表示されます。';
        }
        
        return (
            <>
                <a id='info-tooltip-account3'>
                    <IconContext.Provider value={{ color: '#2B7D54', size: '1em', className: 'icon-vertical-account' }}>
                        <AiFillQuestionCircle />
                    </IconContext.Provider>
                </a>
                <Tooltip
                    anchorId="info-tooltip-account3"
                    style={{ backgroundColor: "#2B7D54", color: "white", maxWidth: "20rem" }}
                >{tooltipContent}</Tooltip>
            </>
        )
    }

    return (
        { isAuthenticated } &&
        <>
            {renderAccountInfo()}
        </>
    )
}

export default Account;