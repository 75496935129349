/*eslint-disable*/

import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMediaQuery } from 'react-responsive';
import '../css/ServiceList.css';
import { metaData, dataValues, metaDataContext } from '../App';
import ListDataJson from './ServiceListData-web-internet.json';
import Modal from 'react-modal';
import { AiFillQuestionCircle } from "react-icons/ai";
import { IconContext } from "react-icons";

// モーダルダイアログのスタイル
const modalStyle: { [key: string]: React.CSSProperties } = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#d9d9d9bf',
        zIndex: 90
    },
    content: {
        position: 'absolute',
        width: '41.5rem',
        maxWidth: '60rem',
        height: '9rem',
        margin: 'auto',
        zIndex: 99,
        backgroundColor: '#fff',
        borderRadius: '1rem',
        padding: '1.5rem'
    }
};

const modalStyleMobile: { [key: string]: React.CSSProperties } = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#d9d9d9bf',
        zIndex: 90
    },
    content: {
        position: 'absolute',
        width: '17.4rem',
        maxWidth: '60rem',
        height: '15rem',
        margin: 'auto',
        zIndex: 99,
        backgroundColor: '#fff',
        borderRadius: '1rem',
        padding: '1.5rem',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
};

const jsonData = ListDataJson;

interface PropsType {
    isOpenModal: boolean;
    isDesktop: boolean;
    updateModal: Function;
};

function ConvertServiceVal(param: string | undefined): string {
    let returnVal = '';
    switch (param) {
        case '0':
            returnVal = '〇'
            break;
        // case '1':
        //     returnVal = '✕'
        //     break;
        case '2':
            returnVal = '？'
            break;
    }
    return returnVal;
}

function ModalPopup({ isOpenModal: isModalOpen, isDesktop, updateModal }: PropsType) {
    const closeModal = () => {
        updateModal(false);
    }

    return (
        <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={isDesktop ? modalStyle : modalStyleMobile}>
            <table style={{ borderCollapse: 'collapse', padding: '0', border: '1px solid #DFDFDF' }}>
                <tbody>
                    <tr>
                        <td rowSpan={3} style={{ width: '4em' }}>利用可能<br />判定結果</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>〇</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF', width: '3em' }}>利用<br />可能</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>ご利用いただけます。</td>
                    </tr>
                    {/* <tr>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>✕</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>利用<br />不可能</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>利用要件を満たさないため、ご利用いただけません。</td>
                    </tr> */}
                    <tr>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>？</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>判定<br />不可能</td>
                        <td style={{ borderLeft: '1px solid #DFDFDF' }}>利用可否を判定できません。連携サービス側へご確認ください。</td>
                    </tr>
                </tbody>
            </table>
        </Modal>
    )
}

const ServiceList: React.FC = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isDesktop = useMediaQuery({ minWidth: 960 })
    const { metaData, setMetaData } = useContext(metaDataContext);
    const [showService, setShowService] = useState<dataValues>();
    const [loginService, setLoginService] = useState<dataValues>();
    const [isOpenModal, setOpenModal] = useState(false);
    const [checkedState, setCheckedState] = useState(Array<boolean>());

    useEffect(() => {
        if (metaData) {
            setShowService(metaData.show_service as dataValues);
            setLoginService(metaData.login_service as dataValues);
            setCheckedState(new Array(jsonData.length).fill(false));
        }
    }, [user?.sub]);

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
    }

    function openModal(e: React.MouseEvent<Element, MouseEvent>) {
        e.preventDefault();
        setOpenModal(!isOpenModal);
    }

    const updateModal = (dispFlg: boolean) => { setOpenModal(dispFlg) }

    const renderServiceList = () => {
        return (
            <>
                {
                    jsonData.map((e, i) => (
                        (showService?.[e.client_id]?.toString() === 'true') &&
                        <tr key={i}>
                            <td className='service'>{e.serviceName}</td>
                            <td className='service'>
                                <p style={{ wordBreak: 'break-all' }}><a href={e.url} target='_blank' rel='noopener noreferrer'>{e.url}</a></p>
                            </td>
                            <td className='service'>{e.description}</td>
                            <td className='service'>{e.usageRequire}</td>
                            <td className='service' style={{ textAlign: 'center' }}>{ConvertServiceVal(loginService?.[e.client_id]?.toString())}</td>
                            <td className='' style={{ textAlign: 'center' }}>{e.useMfa}</td>
                        </tr>
                    ))
                }
            </>
        )
    }
    if (isAuthenticated) {
        if (isDesktop) {
            return (
                <>
                    <div className='screenTitle'>
                        <h1>連携サービス一覧</h1>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <table className='service'>
                            <thead>
                                <tr>
                                    <th className='useinfo-category'>サービス名</th>
                                    <th className='useinfo-category'>URL</th>
                                    <th className='useinfo-category'>説明</th>
                                    <th className='useinfo-category' style={{ minWidth: '100px' }}>利用要件</th>
                                    <th className='useinfo-category' style={{ minWidth: '100px' }}>
                                        利用可能判定
                                        <a href='#' id='openModal' onClick={(e) => { openModal(e) }}>
                                            <IconContext.Provider value={{ color: 'white', size:'1.4em', className:'icon-vertical' }}>
                                                <AiFillQuestionCircle />
                                            </IconContext.Provider>
                                        </a>
                                    </th>
                                    <th className='useinfo-category' style={{ minWidth: '110px' }}>二要素認証の利用</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderServiceList()}
                            </tbody>
                        </table>
                    </div>
                    <ModalPopup isOpenModal={isOpenModal} isDesktop={isDesktop} updateModal={updateModal} />
                </>
            )
        } else {
            return (
                <>
                    <div className='screenTitle'>
                        <h1 style={{ marginLeft: '1rem' }}>連携サービス一覧</h1>
                    </div>
                    {
                        jsonData.map((e, i) => (
                            (showService?.[e.client_id]?.toString() === 'true') &&
                            <div className='serviceContainer' key={i}>
                                <input title='hiddenCheckbox' type='checkbox' className='hiddenCheckbox' checked={checkedState[i]} onChange={() => handleOnChange(i)} />
                                <label className='service' onClick={() => handleOnChange(i)}>
                                    <div style={{ width: '265px', marginTop: '10px' }}>{e.serviceName}</div>
                                </label>
                                <div className='content'>
                                    <div className='content_item'>{e.description}</div>
                                    <div className='content_item'>利用条件</div>
                                    <div>{e.usageRequire}</div>
                                    <div className='content_item'>利用可能判定
                                        <a href='#' id='openModal' onClick={(e) => { openModal(e) }}>
                                            <IconContext.Provider value={{ color: "#2B7D54", size:'1.4em', className:'icon-vertical' }}>
                                                <AiFillQuestionCircle />
                                            </IconContext.Provider>
                                        </a>
                                        ：{ConvertServiceVal(loginService?.[e.client_id]?.toString())}
                                    </div>
                                    <div className='content_item'>二要素認証の有無：{e.useMfa}</div>
                                    {e.url && <div className='content_button'><a className='linkButton' href={e.url} target='_blank' rel='noopener noreferrer'>開く</a></div>}
                                </div>
                            </div>
                        ))
                    }
                    <ModalPopup isOpenModal={isOpenModal} isDesktop={isDesktop} updateModal={updateModal} />
                </>
            )
        }
    } else {
        return null
    }
}

export default ServiceList;