import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { QRCodeSVG } from 'qrcode.react';
import { isMfaLoginContext } from '../App';
import { useMediaQuery } from 'react-responsive';
import { refreshTokenContext } from '../App';
import { GetMfaToken } from '../util/GetMfaToken';
import '../css/RegisterMaffApp.css';

const RegisterMaffApp: React.FC = () => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [codeUrl, setCodeUrl] = useState('');
    const { isMfaLogin, setIsMfaLogin } = React.useContext(isMfaLoginContext);
    const [count, setCount] = useState(0);
    const navigate = useNavigate();
    const {refreshToken, setRefreshToken} = React.useContext(refreshTokenContext);
    const isDesktop = useMediaQuery({ minWidth: 960 })

    useEffect(() => {
        const enrollPushAuthenticator = async () => {
            try {
                // const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access enroll" });
                const token = await GetMfaToken(refreshToken);
                const accessToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);
                if (!accessToken || accessToken === '') {
                    // アクセストークン取得に失敗した場合はエラー
                    setIsMfaLogin(false);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // プッシュ通知認証の登録開始
                // Add Authenticator
                const addAuthUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/associate/`;

                // body
                const params = {
                    "client_id": `${process.env.REACT_APP_AUTH0_CLIENT_ID!}`,
                    "authenticator_types": ["oob"],
                    "oob_channels": ["auth0"]
                }

                // API呼び出し
                const addResponse = await fetch(addAuthUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(params)
                });
                // 結果取得
                const response = await addResponse.json();
                setCodeUrl(response["barcode_uri"]);

            } catch (e) {
                console.log(e);
                navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
            }
        };

        enrollPushAuthenticator();

    }, []);

    useEffect(() => {
        let checkInterval = setInterval(async () => {
            if (count < 100) {
                setCount(count + 1);
                // const accessToken = await getAccessTokenSilently({ audience: `https://${process.env.REACT_APP_AUTH0_TENANT_DOMAIN}/mfa/`, scope: "offline_access read:authenticators" });
                const token = await GetMfaToken(refreshToken);
                const accessToken = token["access_token"];
                setRefreshToken(token["refresh_token"]);

                // List Authenticator
                const authenticatorUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/mfa/authenticators/`;

                const listResponse = await fetch(authenticatorUrl, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                // 取得に失敗した場合はエラー画面へ遷移
                if (listResponse.status !== 200) {
                    const response = await listResponse.json();
                    console.log(response);
                    navigate('/error', { state: { name: '二要素認証の設定' }, replace: true });
                    return;
                }

                // 二要素認証登録内容を取得
                const authenticators = await listResponse.json();
                const pushAuth = authenticators.find((v: { [x: string]: any; }) => v["oob_channel"] === "auth0" && v["active"]);
                if (pushAuth) {
                    navigate('/success_register_mfa', { state: { type: 'push' } });
                }

            } else {
                clearInterval(checkInterval);
            }
        }, 3000);
        return () => {
            clearInterval(checkInterval);
        };
    });

    return (
        <div className='content-wrapper'>
            <div className="screenTitle">
            <h1 style={{ fontSize: "1.9em" }}>プッシュ通知認証の設定</h1>
            </div>
            <div>
                MAFFアプリ（農林水産省）を開き、eMAFFタブにあるQRコード読み取り機能を用いて、下のQRコードをスキャンしてください。<br />
            </div>
            <div className="push-code-wrapper">
                {codeUrl && <QRCodeSVG value={codeUrl} size={250} />}
                <div style={isDesktop ? { marginLeft: "30px", display: "flex", flexDirection: "column" } : {marginTop:"20px", display: "flex", flexDirection: "column"}} >
                    MAFFアプリ（農林水産省）操作手順
                    <img src={"https://" + process.env.REACT_APP_WEB_DOMAIN + "/image/qr_scan.png"} style={{ height: "400px" }} />
                </div>
            </div>
            <div style={{ marginTop: "30px" }}>
                <Link to="/select_mfa" className="button03 w-100">別の方法で設定する</Link>
            </div>
        </div>
    )
};

export default RegisterMaffApp;